import React, { useState, useEffect, createRef, useMemo } from 'react';
import { Navbar, NavbarGroup, NavbarHeading, NavbarDivider, Spinner, Button, Alignment, Callout } from '@blueprintjs/core';
import Single from './Article'
import API from '../Utilities/API'
import OneArticle from './OneArticle'
import U from '../Utilities/Utilities'
import { Drawer, Position, Classes } from '@blueprintjs/core';
import Cases from '../Cases/Cases';
import CaseSelect from '../Cases/SelectAdd'

import Filter from '../Filter/Filter';
import EditSearch from './EditSearch'

import Home from '../Pages/Home'
import ShareButton from '../Share/Button';

export default (props) => {

    const newTimeout = 20; //Seconds to check for new news

    const resizeRef = createRef();

    const [search, setSearch] = useState(false);
    const [news, setNews] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [newCount, setNewCount] = useState(0);
    const [latest, setLatest] = useState('');
    const [loading, setLoading] = useState(false);
    const [moreLoad, setMoreLoad] = useState(false);

    const [pages, setPages] = useState(0);
    const limit = 50;

    const [activeArticle, setActiveArticle] = useState(false)

    const [range, setRange] = useState([null, null]);

    const [showDrawer, setShowDrawer] = useState(false);
    const [drawerCase, setDrawerCase] = useState(null);

    const [selected, setSelected] = useState([]);
    const [selectedUpdate, setSelectedUpdate] = useState(1);

    const [query, setQuery] = useState('');
    const [filter, setFilter] = useState(null);
    const [filterLoaded, setFilterLoaded] = useState(0);

    const [searching, setSearching] = useState(false);
    const [edit, setEdit] = useState(false);

    const [time, setTime] = useState(0);

    const [moreLoading, setMoreLoading] = useState(false);
    const [lastLoad, setLastLoad] = useState(0);

    const [isFiltering, setIsFiltering] = useState(false);

    const onCheck = (group, state) => {
        let newSelected = selected;
        if (state === true) {
            newSelected.push(group);
        } else {
            newSelected = selected.filter((s) => (s.group && s.group.id != group.group.id));
        }
        setSelected(newSelected);
        setSelectedUpdate(new Date().getTime());
    }

    const checkChecked = (group) => {
        return selected.filter((s) => (s.group.id === group.group.id)).length > 0;
    }

    let checkInterval = 0;

    const splitLocation = window.location.href.split('/');
    const last = splitLocation.pop();
    const page = splitLocation.pop();

    let id = props.searchID || '';
    if (id.length === 0 && page === 'intelligence') id = last;

    useEffect(() => {
        clearInterval(checkInterval);
        setNews([]);
        setLoaded(false);
        setPages(0);
        if (id != 'intelligence' && id.length == 20) {
            console.log('Saved search load', id);
            setTime(new Date().getTime());
            API.SavedSearch(id).then((response) => {
                if (!search) setSearch(response.data.Data.search);
                if (query === '') setQuery(response.data.Data.search.query);
                else {
                    console.log('Query not set because query =', query);
                }
                if (response.data.Data.parsed) {
                    if (filter == null) {
                        setFilter({
                            ...response.data.Data.parsed,
                            ...filter,
                        });
                        setFilterLoaded(filterLoaded + 1);
                    }
                }
                if (query != '') {
                    API.Intelligence(id, query, null, range, pages * limit, isFiltering).then((r) => {
                        setNews(r.data.Data && r.data.Data.hits ? r.data.Data.hits : []);
                        setLoaded(true);
                        if (r.data.Data && r.data.Data.hits) setLatest(r.data.Data.hits[0]._source.timestamp);
                    })
                }
            })
        } else {
            setTime(new Date().getTime());
            API.IntelligenceNews(query, pages * limit).then((r) => {
                if (r.data.Data.hits && r.data.Data.hits.length > 0) {
                    setNews(r.data.Data.hits);
                    setLatest(r.data.Data.hits[0]._source.timestamp);
                } else {
                    setNews([]);
                }
                setLoaded(true);
            })
        }
    }, [range, query]);

    useEffect(() => {
        if (news.length > 0) {
            setTime(new Date().getTime());
            API.IntelligenceNews(query, pages * limit).then((r) => {
                if (r.data && r.data.Data && r.data.Data.hits) {
                    setNews([...news, ...r.data.Data.hits]);
                }
                setMoreLoad(false);
            })
        }
    }, [pages])

    let lastActive = null;

    /*
    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            const activeArticle = document.querySelector('.currentlyOpenedArticle');
            if (activeArticle) {
                lastActive = activeArticle;
                activeArticle.scrollIntoViewIfNeeded({ behavior: "smooth", block: "center" });
            } else {
                if (lastActive) lastActive.scrollIntoViewIfNeeded({ behavior: "smooth", block: "center" });
            }
        });

        try {
            resizeObserver.observe(document.getElementById('divRenderNews'));
        } catch (e) {
            console.log('Unable to observe', e);
        }

        return () => {
            try {
                const el = document.getElementById('divRenderNews');
                if (el) resizeObserver.unobserve(el);
            } catch (e) {
                console.log('Unable to unobserve', e);
            }
        }

    })
        */

    const loadMore = () => {
        if (news.length === 0) return false;

        let min = '9999-99-99T99:99:99';
        news.forEach((group) => {
            group.news.forEach((article) => {
                if (article.timestamp < min) min = article.timestamp;
            })
        })
        const latestArticle = min.replace(/T/, ' ');

        setMoreLoading(true);
        if (search) {
            API.Intelligence(id, search.query, latestArticle, range, pages * limit).then((r) => {
                setNews((oldNews) => {
                    const response = r.data.Data && r.data.Data.hits ? r.data.Data.hits : [];
                    const newNews = [
                        ...oldNews,
                        ...response
                    ];
                    return newNews;
                });
                setMoreLoading(false);
                setLastLoad(new Date().getTime());
            })
        } else {
            API.IntelligenceNews(query, pages * limit, null, latestArticle).then((r) => {
                setNews((oldNews) => {
                    const response = r.data.Data ? r.data.Data : [];
                    const newNews = [
                        ...oldNews,
                        ...response
                    ];
                    return newNews;
                });
                setMoreLoading(false);
                setLastLoad(new Date().getTime());
            })
        }

    }

    const renderNews = () => {
        let date = '';

        if (news.length === 0) {
            return (
                <Callout
                    intent={'primary'}
                    title={'No results'}
                >
                    <>
                        Your search has not returned any results. Please try with another query.
                        <br /><br />
                        <small>Time: <em>{`${(new Date().getTime() - time) / 1000}`}s</em></small>
                    </>
                </Callout>
            );
        }

        if (news.length > 0 && news[0].group) {
            return (
                <Home
                    key={`Home-${lastLoad}`}
                    data={news}
                    noUpdate
                    hideSearch
                    hideGroupsOnly
                    appendButtons={() => (
                        <div style={{ padding: 10 }}>
                            <Button large loading={moreLoading} fill onClick={() => {
                                loadMore()
                            }}>Load more</Button>
                        </div>
                    )}
                />
            )
        }

        return (
            <>
                {newCount > 0 ? (
                    <Button large fill loading={loading} style={{ background: '#1F4B99' }} onClick={() => {
                        setLoading(true);
                        API.IntelligenceNews().then((r) => {
                            let newList = [];
                            let newNews = r.data.Data.hits.forEach((hit) => {
                                if (hit._source.timestamp > latest) {
                                    newList.push({
                                        ...hit,
                                        isNew: true
                                    });
                                }
                            });
                            news.forEach((article) => {
                                newList.push({
                                    ...article,
                                    isNew: false
                                })
                            })
                            setNews(newList);
                            setLatest(newList[0]._source.timestamp);
                            setLoading(false);
                            setNewCount(0);
                        })
                    }}>
                        There are <strong>{newCount}</strong> new articles. Click here to show.
                    </Button>
                ) : null}
                {news.map((n) => {
                    let cDate = U.date(n._source.timestamp);
                    let showDate = false;
                    if (cDate != date) {
                        date = cDate;
                        showDate = true;
                    }
                    return (
                        <>
                            {showDate ? (<Button
                                fill
                                minimal
                                disabled
                                large
                                icon={'calendar'}
                                style={{ color: '#FFB366', cursor: 'default', position: 'sticky', top: 0, backgroundColor: '#30404d', zIndex: 1 }}
                            >{date}</Button>) : null}
                            <Single
                                {...props}
                                article={n}
                                onClick={() => {
                                    if (activeArticle._id === n._id) setActiveArticle(false);
                                    else setActiveArticle(n);
                                }}
                                active={activeArticle._source && activeArticle._id == n._id}
                                onCheck={onCheck}
                                checkChecked={checkChecked}
                                key={`SingleIntelligenceArticle-${selected.join('-')}`}
                            />
                        </>
                    );
                })}
                {news.length > 0 && (<div style={{ padding: 10 }}>
                    <Button large fill loading={moreLoad} onClick={() => {
                        setMoreLoad(true);
                        setPages(pages + 1);
                    }}>Load more</Button>
                </div>)}
            </>
        )
    }

    const DeleteSearch = () => {
        if (search && search.id && window.confirm('Delete the saved search?')) {
            API.DeleteIntelligenceSearch(search.id).then(() => {
                window.askUnload = false;
                window.location.href = '/intelligence';
            })
        }
    }

    return (
        <>
            <Navbar style={{ position: 'sticky', top: 0, paddingLeft: 0 }}>
                <NavbarGroup>
                    <NavbarHeading>
                        Intelligence{search && search.title ? (<>
                            <span>» {search.title} ({search.user_name})</span>
                            {search.user_id === window.nd.User.id ? (
                                <>
                                    <ShareButton style={{ marginLeft: 10 }} minimal text={false} content_id={`search:${search.id}`} />
                                    <Button minimal small intent={'danger'} icon={'trash'} style={{ marginLeft: 5 }} onClick={DeleteSearch} />
                                </>
                            ) : null}
                        </>) : null}
                    </NavbarHeading>
                    <>
                        <NavbarDivider />
                        <NavbarGroup>
                            <CaseSelect selectedCase={props.caseID} forceCase={props.forceCase} key={`CaseSelect-${selectedUpdate}`} articles={selected.map((g) => (g.group.id))} onSelect={(casedata) => {
                                selected.forEach((g) => {
                                    const id = g.group.id;
                                    if (window['reloadCaseTitle' + id]) window['reloadCaseTitle' + id]();
                                })
                                setSelected([]);
                                if (!props.caseID) {
                                    if (window.confirm('Open case?')) {
                                        setDrawerCase(casedata);
                                        setShowDrawer(true);
                                    }
                                } else {
                                    props.onSelect && props.onSelect();
                                }
                            }} />
                        </NavbarGroup>
                    </>
                </NavbarGroup>
            </Navbar>
            <Navbar fixedToTop>
                <NavbarGroup align={Alignment.CENTER} style={{ width: 1600, margin: 'auto' }}>
                    {edit && (!search.id || (search.user_id === window.nd.User.id) || (window.nd.User && window.nd.User.permissions && window.nd.User.permissions.saved_search_edit_all)) && (
                        <EditSearch
                            search={search}
                            onClose={() => {
                                setEdit(false);
                            }}
                            key={`SaveSearch`}
                            onSave={(title, folder) => {
                                let q = query;
                                if (id != 'intelligence' && id.length == 20) q += '&search_id=' + id;

                                API.SaveIntelligenceSearch(q, title, folder).then((response) => {
                                    window.askUnload = false;
                                    if (response.data && response.data.Data && response.data.Data.id) {
                                        if (props.onSavedSearchCreate) {
                                            props.onSavedSearchCreate(response.data.Data);
                                            setEdit(false);
                                        }
                                        else {
                                            if (props.closeSavedSearch) {
                                                setEdit(false);
                                                if (window.confirm('Changes saved. Close Saved Search?')) {
                                                    props.closeSavedSearch();
                                                }
                                            }
                                            else if (window.top.location.href.indexOf('/cases/') != -1) {
                                                setEdit(false);
                                                alert('Changes saved');
                                            } else {
                                                window.top.location.href = `/intelligence/${response.data.Data.id}`;
                                            }
                                        }
                                    }
                                })
                            }}
                        />
                    )}
                    <Filter
                        initial={filter}
                        key={`Filter-${filterLoaded}`}
                        searching={searching}
                        search={search}
                        range={range}
                        onChangeRange={(range) => {
                            console.log('New range', range);
                            setRange(range);
                        }}
                        searchStyle={{
                            width: 1200,
                            maxWidth: 1200
                        }}
                        onSearch={(query) => {
                            console.log('onSearch', query);
                            setPages(0);
                            setActiveArticle(false);
                            setQuery(query);
                            setSearching(true);
                            setIsFiltering(true);
                        }}
                        onSave={() => {
                            setEdit(true);
                        }}
                        setKeywords={(kws) => {
                            const f = { ...filter };
                            f.keyword = kws;
                            setFilter(f);
                        }}
                        setCountries={(countries) => {
                            const f = { ...filter };
                            f.country = countries;
                            setFilter(f);
                        }}
                        setLanguages={(languages) => {
                            const f = { ...filter };
                            f.language = languages;
                            setFilter(f);
                        }}
                        setKwType={(kwtype) => {
                            const f = { ...filter };
                            f.kwtype = kwtype;
                            setFilter(f);
                        }}
                        onReset={() => {
                            setPages(0);
                            setFilter(null);
                        }}
                    />
                </NavbarGroup>
            </Navbar>
            <div style={{ display: 'block', width: '100%', height: 'calc(100% - 100px)', overflow: 'hidden' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: (activeArticle._source ? '30%' : '100%'),
                        height: '100%',
                        float: 'left',
                        backgroundColor: '#30404d'
                    }}
                    id={'divRenderNews'}
                >
                    {activeArticle._source ? (<div
                        className={'bp5-navbar fakeBar'}
                        style={{
                            flex: 1,
                            flexGrow: 0,
                            flexShrink: 0,
                            flexBasis: 49,
                            top: 1
                        }}
                    >
                        <Button
                            className={'bp5-disabled'}
                            icon={'maximize'} style={{ position: 'absolute', top: 1, right: 0, height: 48, cursor: 'default', width: 50 }}
                            onClick={() => {
                                setActiveArticle(false)
                            }}
                        />
                    </div>
                    ) : null}
                    <div style={{
                        flex: 1,
                        flexGrow: 1,
                        overflow: 'auto'
                    }}>
                        {loaded ? renderNews() : <div style={{ marginTop: 50 }}><Spinner /></div>}
                    </div>
                </div>
                {activeArticle._source ? (
                    <div style={{
                        width: '70%',
                        height: '100%',
                        overflow: 'hidden'
                    }}>
                        <OneArticle
                            key={`Intelligence-${activeArticle._id}`}
                            article={activeArticle}
                            scrollBack={() => {
                                console.log('Scrolling back');
                                const activeArticle = document.querySelector('.currentlyOpenedArticle');
                                if (activeArticle) {
                                    activeArticle.scrollIntoViewIfNeeded({ behavior: "smooth", block: "center" });
                                }
                            }}
                        />
                    </div>
                ) : null}
            </div>
            {showDrawer && (
                <Drawer
                    icon={'box'}
                    title={drawerCase.title}
                    autoFocus
                    canEscapeKeyClose={false}
                    canOutsideClickClose={false}
                    enforceFocus
                    hasBackdrop
                    position={Position.RIGHT}
                    size={'100%'}
                    isOpen={true}
                    className={Classes.DARK}
                    onClose={() => {
                        setDrawerCase(null);
                        setShowDrawer(false);
                    }}
                >
                    <Cases
                        popup
                        case={drawerCase}
                        key={drawerCase.id}
                        setCaseText={(text) => {

                        }}
                        reloadCases={() => {

                        }}
                        onCloseCase={() => {
                            setDrawerCase(null);
                            setShowDrawer(false);
                        }}
                    />
                </Drawer>
            )}
        </>
    )

}